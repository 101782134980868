import getUniqueNumber from './utils/uniqueId'

export const SITE_NAME = 'Thrivestate'

export const PERMISSIONS = {}

export const ENVS = {
  DEV: 'dev',
  STAGING: 'STAGING',
  PROD: 'prod',
}

export const DEFAULT_DEBOUNCE_INPUT_TIME = 500

export enum DICTIONARIES {
  SALE_STATUS = 'sale_status',
  PROPERTY_TYPE = 'property_type',
  DEVELOPER_NAMES = 'developer_names',
  LOCATION = 'location',
  PROPERTY_AMENITY = 'property_amenity',
  EXCLUSIVE_UNIT_BEDROOM_TYPES = 'exclusive_unit_bedroom_types',
  EXCLUSIVE_UNIT_STATUSES = 'exclusive_unit_statuses',
  EXCLUSIVE_UNIT_TYPES = 'exclusive_unit_types',
  PROPERTIES = 'properties',
  FINANCE_DEVELOPERS = 'finance_developers',
  IMAGE_TYPES = 'image_types',
  EMIRATE_NAMES = 'emirate_names',
}

export const PROPERTIES_SALE_STATUSES = {
  ANNOUNCEMENT: 'announcement',
  LAUNCH: 'launch',
  ON_SALE: 'on_sale',
  PENDING: 'pending',
  PRE_SALE: 'presale(_e_o_i)',
  SOLD_OUT: 'sold_out',
}

export const PROPERTIES_ROOMS_MAP: { [key: string]: string } = {
  rooms_studio: 'Studio',
  rooms_1: '1 BR',
  rooms_2: '2 BR',
  rooms_3: '3 BR',
  rooms_4: '4 BR',
  rooms_5: '5 BR',
  rooms_6: '6 BR',
}

export enum USER_STATUS_CODES {
  INVITED = 'invited',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const TOKEN_KEY = 'token'

export const EXCLUSIVE_PLACEHOLDER_ID = getUniqueNumber()

export enum DLD_VALUES {
  BOOKING = 1,
  HANDOVER = 2,
}

export enum PAYMENT_PLAN_KEYS {
  ON_BOOKING = 'on_booking_percent',
  ON_HANDOVER = 'on_handover_percent',
}

export const DEFAULT_DLD_PERCENTAGE = '4'
export const NO_DRAG_CLASS = 'no-draggable'
export const DRAG_CLASS = 'draggable'

export enum IMAGE_TYPES_CODES {
  PROPERTY_EXTERNAL_IMAGE = 'property_external_image',
  PROPERTY_INTERNAL_IMAGE = 'property_internal_image',
}
